import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  CircularProgress
} from "@material-ui/core";

interface DialgoueProps {
  open: boolean;
  title: string;
  body: React.ReactNode | string;
  submitBtnText: string;
  cancelBtnText?: string;
  handleCancel: Function;
  handleSubmit: Function;
  isLoading?: boolean;
}

const CommonDialogue = (props: DialgoueProps) => {
  const {
    open,
    title,
    body,
    submitBtnText,
    cancelBtnText,
    handleSubmit,
    handleCancel,
    isLoading
  } = props;
  return (
    <>
      <StyledDialogue
        data-test-id="dialogue"
        maxWidth={"xs"}
        open={open}
        onClose={() => handleCancel()}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{body}</DialogContent>
        <DialogActions>
          {cancelBtnText && (
            <Button
              onClick={() => handleCancel()}
              variant="outlined"
              className="cancelBtn"
            >
              {cancelBtnText}
            </Button>
          )}
          <Button
            onClick={() => handleSubmit()}
            color="primary"
            variant="contained"
            className="submitBtn"
            disabled={isLoading}
          >
            {!isLoading ?
              submitBtnText :
              <CircularProgress
                size={24}
                className="submitBtn"
              />
            }
          </Button>
        </DialogActions>
      </StyledDialogue>
    </>
  );
};

const StyledDialogue = styled(Dialog)({
  "& .MuiDialogTitle-root": {
    fontSize: "24px",
    fontWeight: 400,
    color: "#14101E",
    borderBottom: "1px solid #E2E8F0",
    fontFamily: "DM Sans",
  },
  "& .MuiDialogContent-root": {
    fontSize: "16px",
    color: "#14101E",
    backgroundColor: "white",
    fontFamily: "DM Sans",
  },
  "& .MuiDialogActions-root": {
    display: "flex",
    justifyContent: "space-between",
    padding: "24px 32px",
  },
  "& .MuiButton-root": {
    fontSize: "16px",
    fontWeight: 400,
    padding: "10px 16px",
    textTransform: "none",
    width: "100%",
    fontFamily: "DM Sans",
    height: "44px",
    "& .MuiButton-label": {
      height: "24px"
    },
  },
  "& .cancelBtn": {
    color: "#684EF3",
    backgroundColor: "#FFFFFF",
    border: "1px solid #684EF3",
  },
  "& .submitBtn": {
    color: "#FFFFFF",
    backgroundColor: "684EF3",
  },
  "& .MuiPaper-elevation24": {
    boxShadow: "none",
  },
  "& .MuiDialog-paperWidthXs": {
    maxWidth: "496px",
  },
  "& .MuiTypography-body1": {
    fontSize: "16px",
    fontFamily: "DM Sans",
  },
  "& .MuiTypography-h6": {
    fontSize: "24px",
    fontFamily: "DM Sans",
  },
});

export default CommonDialogue;
