// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  openChatBot: boolean;
  activeTab: number;
  anchorEl: HTMLButtonElement | null;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class AiQueryHubController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      openChatBot: true,
      activeTab: 0,
      anchorEl: null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    // Customizable Area End
  }

  // Customizable Area Start

  handleDrawerOpen = () => {
    this.setState({ openChatBot: true });
  };

  handleDrawerClose = () => {
    this.setState({ openChatBot: false });
  };

  handleTabChange = (_: React.ChangeEvent<{}>, value: number) => {
    this.setState({ activeTab: value })
  }

  openMoreMenu =  (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  closeMoreMenu = () => {
    this.setState({ anchorEl: null });
  };
 
  // Customizable Area End
}