// Customizable Area Start
import React from "react";
import AiQueryHubController, { Props, configJSON } from "./AiQueryHubController.web";
import { AppBar, Box, Button, createTheme, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Popover, styled, Tab, Tabs, ThemeProvider, Typography } from "@material-ui/core";
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { doubleLeftIcon, logoWhite, download, regenerate, share } from "./assets";
import AiQueryChat from "./AiQueryChat.web";
import Results from "./Results.web";
import Json from "./Json.web";
import AiQueryHubFilesTab from "./AiQueryHubFilesTab.web";
import AiQueryHubHistoryTab from "./AiQueryHubHistoryTab.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2EAB65",
    },
    secondary: {
      main: "#4768BE",
    },
    text: {
      primary: "#14101E",
    },
    background: {
      default: "#F8F8F8",
    },
  },
  typography: {
    fontFamily: '"DM sans", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: "26px",
      lineHeight: 1.25,
      fontWeight: 800,
      color: "#27292A",
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    subtitle2: {
      lineHeight: 1.25,
      fontSize: "24px",
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    body2: {
      fontSize: "14px",
      color: "#30353B",
      fontWeight: 400,
      "@media (max-width: 600px)": {
        fontSize: "12px",
      },
    },
  },
  overrides: {
    MuiIconButton: {
      root: {
        padding: "6px"
      }
    }
  }
});

const drawerWidth = 440;
// Customizable Area End

export default class AiQueryHub extends AiQueryHubController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  menuOptions = [
    { text: 'Download', iconSrc: download, onClick: () => console.log('Download clicked') },
    { text: 'Share', iconSrc: share, onClick: () => console.log('Share clicked') },
    { text: 'Regenerate', iconSrc: regenerate, onClick: () => console.log('Regenerate clicked') },
  ];

  renderMainContent = () => {

    const { activeTab } = this.state;

    switch (activeTab) {
      case 0:
        return <Results navigation={this.props.navigation} />;
      case 1:
        return <Json navigation={this.props.navigation} />;
      case 2:
        return <AiQueryHubFilesTab />;
      default:
        return <AiQueryHubHistoryTab/>;
    }
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <BoxWrapper>

          <AppBarStyled style={{
            width: this.state.openChatBot ? `calc(100% - ${drawerWidth}px)` : "100%",
            marginRight: this.state.openChatBot ? drawerWidth : 0
          }}>
            <Box className="header">
              <Tabs
                value={this.state.activeTab}
                onChange={this.handleTabChange}
                className="tab__wrapper"
                TabIndicatorProps={{ style: { display: "none" } }}
                data-test-id="tab"
              >
                <Tab label="Results" className="tab" />
                <Tab label="JSON" className="tab" />
                <Tab label="Files" className="tab" />
                <Tab label="History" className="tab" />
              </Tabs>

              <Box className="sub__menu">
                <Box className="version">
                  <Typography variant="body2" component="span">
                    {configJSON.versionLabel}
                    &nbsp;
                    2/2
                    &nbsp;
                  </Typography>

                  <IconButton><KeyboardArrowLeftIcon /></IconButton>
                  <IconButton disabled><KeyboardArrowRightIcon /></IconButton>
                </Box>

                <Divider className="divider" orientation="vertical" variant="middle" flexItem />

              <IconButton aria-describedby="id" data-test-id="more-menu" className={`more__icon ${Boolean(this.state.anchorEl) && "active"} `} onClick={this.openMoreMenu}>
                <MoreVertIcon />
              </IconButton>

                <StyledPopover
                  data-test-id="popover-menu"
                  open={Boolean(this.state.anchorEl)}
                  anchorEl={this.state.anchorEl}
                  onClose={this.closeMoreMenu}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  PaperProps={{
                    className: "popover__paper"
                  }}
                >
                  <List className="list">
                    {this.menuOptions.map((option, index) => (
                      <ListItem className="list__item" button key={index} onClick={option.onClick}>
                        <ListItemIcon className="list__icon"><img src={option.iconSrc} /></ListItemIcon>
                        <ListItemText className="list__label" primary={option.text} />
                      </ListItem>
                    ))}
                  </List>
                </StyledPopover>


              </Box>
            </Box>
            {
              !this.state.openChatBot && (
                <Button className="neeve__btn" data-test-id="open-drawer" onClick={this.handleDrawerOpen}>
                  <img src={doubleLeftIcon} alt="open" className="open__icon" />
                  <img src={logoWhite} alt="logo" className="logo" />
                  Neeve
                </Button>
              )
            }

          </AppBarStyled>

          <ContentWrapper style={{ marginRight: this.state.openChatBot ? drawerWidth : 0 }}>
            {this.renderMainContent()}
          </ContentWrapper>

          <DrawerStyled
            anchor="right"
            variant="persistent"
            open={this.state.openChatBot}
            PaperProps={{
              style: {
                position: this.state.openChatBot ? "absolute" : "inherit",
                display: this.state.openChatBot ? "block" : "none"
              }
            }}
          >
            <AiQueryChat navigation={this.props.navigation} handleDrawerClose={this.handleDrawerClose} />
          </DrawerStyled>
        </BoxWrapper>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const BoxWrapper = styled("div")({
  position: "relative",
  display:"flex",
  flexDirection: "column",
  flex: 1,
  height: "1px",
  "& .tab__wrapper": {
    borderRadius: "12px",
    border: "none",
    padding: "4px",
    backgroundColor: "#FFF",
    alignItems: "center",
    maxHeight: "36px",
    minHeight: "unset",
    "& .tab": {
      minWidth: "unset",
      padding: "3px 12px",
      textTransform: "none",
      minHeight: "unset",
      color: "#5E6671",
      border: "solid 1px #D9D1EF",
      borderColor: "#FFF",
      borderRadius: "6px",
      maxHeight: "28px",
      "&.Mui-selected": {
        backgroundColor: "#EAE6FF",
        border: "solid 1px #D9D1EF",
        color: "#684EF3",
      }
    },
  }
});

const ContentWrapper = styled("main")({
  flex: 1,
  overflowY: "auto"
  // transition: "margin 0.3s ease-in-out",
});

const AppBarStyled = styled(AppBar)({
  // transition: "margin 0.3s ease-in-out",
  position: "relative",
  background: "#E2E8F0",
  boxShadow: "none",
  display: "flex",
  flexDirection: "row",
  overflow: "hidden",
  "& .header": {
    display: "flex",
    padding: "16px 24px !important",
    maxHeight: "68px",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    boxSizing: "border-box",
    flex: 1,
    "& .sub__menu": {
      display: "flex",
      alignItems: "center",
      gap: "16px",
      "& .version": {
        display: "flex",
        alignItems: "center"
      },
      "& .more__icon": {
        borderRadius: "6px",
        height: "32px",
        width: "32px",
        color: "#14101E",
        "&.active": { 
          backgroundColor: "#EAE6FF",
          border: "solid 1px #D9D1EF",
        }
      },
      "& .divider": {
        margin: "5px auto",
        marginLeft: 0
      }
    },
  },
  "& .neeve__btn": {
    background: "#684EF3",
    color: "#FFF",
    display: "flex",
    alignItems: "center",
    padding: "22px 20px",
    borderRadius: 0,
    fontSize: "18px",
    lineHeight: "27px",
    textTransform: "none",
    "& .open__icon": {
      marginRight: "12px"
    },
    "& .logo": {
      marginRight: "6px"
    },
  }
});

const StyledPopover = styled(Popover)({
  "& .popover__paper": {
    borderRadius: "8px",
    padding: "4px",
    marginTop: "4px",
    border: "solid 1px #E2E8F0",
    boxShadow: "0px 4px 6px -2px #684EF333",
  },
  "& .list": {
    paddingTop: 0,
    paddingBottom: 0,
    "& .list__item": {
      maxHeight: "36px",
      minWidth: "170px",
      "& .list__icon": {
        marginRight: "10px",
        minWidth: "unset"
      },
      "& .list__label": {
        color: "#30353B",
        fontSize: "14px",
        lineHeight: "21px"
      }
    }
  }
})

const DrawerStyled = styled(Drawer)({
  width: drawerWidth,
  flexShrink: 0,
  "& .MuiPaper-root": {
    transition: "none !important",
  },
  "& .MuiDrawer-paper": {
    width: drawerWidth,
  },
});
// Customizable Area End