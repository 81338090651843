// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  handleDrawerClose?: () => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  queryInput: string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class AiQueryChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      queryInput: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    // Customizable Area End
  }

  // Customizable Area Start
  onQueryChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    this.setState({ queryInput: event.target.value });
  }

  testingMessages = [
    { messageId: 1, sender: "ME", message: "Can you provide an overview of insurance responsibilities for landlords and tenants in commercial leases, including any financial amounts involved?"},
    { messageId: 2, sender: "AI", message: "The uploaded business lease files contain valuable insights. This overview delves into insurance responsibilities for landlords and tenants within commercial leases, where were explored coverage types and financial aspects, empowering informed decision-making."},
    { messageId: 1, sender: "ME", message: "Make it short, only essential information"},
    { messageId: 1, sender: "AI", message: "Sure, To maintain clarity and focus, the key information has been distilled into 5 essential categories."},
  ]
 
  // Customizable Area End
}