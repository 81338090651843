export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const uploadIcon = require("../assets/upload.png");
export const fileIcon = require("../assets/fileIcon.svg");
export const avtarIcon = require("../assets/avtarIcon.png");
export const downloadFilIcon = require("../assets/downloadIcon.svg");
export const resumeImg = require("../assets/resume.png");
export const deleteImg = require("../assets/delete.png");

export const avatarImg = require("../assets/avatar.png");
export const download = require("../assets/download.png");
export const share = require("../assets/share.png");
export const regenerate = require("../assets/regenerate.png");
export const logoWhite = require("../assets/logo_white.png");
export const logoBlack = require("../assets/logo_black.png");
export const doubleRightIcon = require("../assets/double__right.png");
export const doubleLeftIcon = require("../assets/double__left.png");
export const submitIcon = require("../assets/submit_icon.png");
