import React from "react";
import {
  Box,
  styled,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import { colors } from "../../../blocks/utilities/src/Colors";
import { downloadFilIcon, sampleFileIcon } from "./assets";
import CommonDropZone from "./CommonDropZone.web";
import { uploadFileOptions } from "../../../blocks/utilities/src/constant";
import CommonButton from "./CommonButton.web";

interface Props {
  open: boolean;
  onClose: () => void;
  fileName: string;
  onDrag: (files: File[]) => void;
  applyFileActionVal: string;
  onChangeAction: (
    value: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => void;
  onReplaceFile: () => void;
}

const ReplaceFileDialog = (props: Props) => {
  const {
    open,
    onClose,
    fileName,
    onDrag,
    applyFileActionVal,
    onChangeAction,
    onReplaceFile,
  } = props;
  return (
    <ReplaceFileDialogWrapper
      open={open}
      onClose={onClose}
      data-test-id="replace-file-dialog"
    >
      <DialogTitle>Replace file</DialogTitle>
      <DialogContent>
        <Box className="view-file-list">
          <Box className="view-file-wrapper">
            <Box className="view-file-item">
              <Box className="file-image">
                <img src={sampleFileIcon} alt="fileIcon" />
              </Box>
              <Box>
                <Typography className="file-name">PP0382.doc</Typography>
                <Typography className="file-size">50MB</Typography>
              </Box>
            </Box>
            <IconButton className="file-download-button">
              <img src={downloadFilIcon} alt="downloadFilIcon" />
            </IconButton>
          </Box>
        </Box>
        <Box className="drop-box">
          <CommonDropZone
            onDrag={onDrag}
            dragBoxPara={
              <Typography className="drag-text">
                To replace{" "}
                <span className="file-name-highlight">{fileName}</span> file
                please drag and drop new file or
              </Typography>
            }
          />
        </Box>
        <Box>
          <Typography className="select-upload-label">
            Is there an action required to apply to this file?
          </Typography>
          <FormControl fullWidth variant="filled">
            <SelectComponent
              value={applyFileActionVal}
              onChange={onChangeAction}
              className="upload-select"
              data-test-id="select-action-type"
              displayEmpty={true}
              MenuProps={{
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              }}
            >
              {uploadFileOptions.map((option) => {
                return <MenuItem value={option.value}>{option.label}</MenuItem>;
              })}
            </SelectComponent>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <CommonButton
          dataTestId="replace-dialog-cancel-btn"
          onClick={onClose}
          label="Cancel"
          variant="secondary"
        />
        <CommonButton
          dataTestId="replace-dialog-submit-btn"
          onClick={onReplaceFile}
          label="Continue"
        />
      </DialogActions>
    </ReplaceFileDialogWrapper>
  );
};

const ReplaceFileDialogWrapper = styled(Dialog)({
  "& .MuiDialog-paperWidthSm": {
    width: "100%",
    maxWidth: "496px",
  },
  "& .MuiDialog-paper": {
    borderRadius: 24,
    boxShadow: "0px 24px 48px -12px #684EF333",
  },
  "& .MuiDialogTitle-root": {
    padding: "24px 16px 24px 40px",
  },
  "& .MuiTypography-root": {
    fontFamily: "DM Sans",
    fontSize: "24px",
    lineHeight: "18px",
    fontWeight: 400,
    color: colors().chineseBlack,
  },
  "& .MuiDialogContent-root": {
    display: "flex",
    flexDirection: "column",
    gap: 28,
    padding: "24px 32px",
    borderTop: `1px solid ${colors().tableBorder}`,
    borderBottom: `1px solid ${colors().tableBorder}`,
  },
  "& .MuiDialogActions-root": {
    padding: "26px 32px 32px",
    display: "grid",
    gridTemplateColumns: "1fr 2fr",
  },
  "& .view-file-list": {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  "& .view-file-wrapper": {
    padding: "12px",
    backgroundColor: colors().cultured,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "& .view-file-item": {
    display: "flex",
    alignItems: "center",
    gap: 12,
  },
  "& .file-image": {
    minWidth: "56px",
    minHeight: "56px",
    maxWidth: "56px",
    maxHeight: "56px",
    backgroundColor: colors().soapPurple,
    borderRadius: 4,
    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  "& .file-name": {
    fontFamily: "DM Sans",
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: 700,
    color: colors().chineseBlack,
  },
  "& .file-size": {
    fontFamily: "DM Sans",
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: 400,
    color: colors().darkText,
  },
  "& .file-download-button": {
    backgroundColor: colors().white,
    padding: 4,
    borderRadius: 8,
    minWidth: 32,
    minHeight: 32,
  },
  "& .drag-text": {
    fontFamily: "DM Sans",
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: 400,
    color: colors().darkText,
    textAlign: "center",
    maxWidth: "288px",
  },
  "& .file-name-highlight": {
    fontWeight: 700,
  },
  "& .drop-box": {
    margin: "",
  },
  "& .select-upload-label": {
    fontFamily: "DM Sans",
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: 700,
    color: colors().labelColor,
  },
  "& .upload-select": {
    width: "100%",
    borderRadius: "8px",
    fontFamily: "DM Sans",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    border: "1px solid #E2E8F0",
    backgroundColor: "#fff",
    "& .MuiFilledInput-input": {
      padding: "12px",
      height: "fit-content",
    },
    "&::before, &::after": {
      borderBottom: "none !important",
    },
    "&:hover": {
      textDecoration: "none !important",
    },
  },
});

export const SelectComponent = styled(Select)({
  "& .MuiSelect-select:focus": {
    backgroundColor: "rgba(0,0,0,0)",
  },
  "& .MuiSelect-selectMenu": {
    height: 0,
  },
});

export default ReplaceFileDialog;
