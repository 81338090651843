import React from "react";
// Customizable Area Start
import AiQueryHubFilesTabController, {
  Props,
  configJSON,
} from "./AiQueryHubFilesTabController.web";
import {
  Box,
  styled,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  IconButton,
  Avatar,
  Dialog,
} from "@material-ui/core";
import { avtarIcon } from "./assets";
import { colors } from "../../../blocks/utilities/src/Colors";
import {
  MoreVert,
  DescriptionOutlined,
  Cached,
  DeleteOutline,
} from "@material-ui/icons";
import DragAndDropBox from "../../../components/src/common/DragAndDropBox.web";
import ReplaceFileDialog from "../../../components/src/common/ReplaceFileDialog.web";
import TableFileView from "../../../components/src/common/TableFileView.web";
// Customizable Area End

// Customizable Area Start
const string = configJSON.filesTabString;
// Customizable Area End

export class AiQueryHubFilesTab extends AiQueryHubFilesTabController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  actionMenu = () => {
    const { actionMenuAnchor } = this.state;
    const open = Boolean(actionMenuAnchor);
    return (
      <StyledMenu
        data-test-id="action-menu"
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={actionMenuAnchor}
        open={open}
        onClose={this.onCloseActionMenu}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        getContentAnchorEl={null}
      >
        <MenuItem onClick={this.onCloseActionMenu}>
          <DescriptionOutlined className="menu-icon" />
          <Typography className="menu-item">View File</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => this.handleOpenReplaceDialog()}
          data-test-id="replace-file-menu"
        >
          <Cached className="menu-icon" />
          <Typography className="menu-item">Replace file</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => this.handleOpenDeleteDialog()}
          data-test-id="delete-file-menu"
        >
          <DeleteOutline className="menu-icon delete-menu-icon" />
          <Typography className="menu-item delete-menu-item">
            Delete file
          </Typography>
        </MenuItem>
      </StyledMenu>
    );
  };

  replaceFileDialog = () => {
    const { replaceFileDialogOpen, applyFileActionVal } = this.state;
    return (
      <ReplaceFileDialog
        open={replaceFileDialogOpen}
        onClose={this.closeReplaceFileDialog}
        fileName={"PP0382.doc"}
        onDrag={this.onDrag}
        applyFileActionVal={applyFileActionVal}
        onChangeAction={(
          event: React.ChangeEvent<{ name?: string; value: unknown }>
        ) => this.handleChangeActionVal(event)}
        onReplaceFile={this.handleReplaceFile}
      />
    );
  };

  renderDeleteDialog = () => {
    const { isOpenDeleteDialog } = this.state;
    return (
      <Dialog open={isOpenDeleteDialog} onClose={this.handleCloseDeleteDialog}>
        <Typography>
          This is delete dialog, will replace by nikhil component
        </Typography>
      </Dialog>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { actionMenuAnchor, selectedRowId } = this.state;
    const open = Boolean(actionMenuAnchor);
    // Customizable Area End
    return (
      // Customizable Area Start
      <GeneralTabViewWrapper>
        <Box>
          <DragAndDropBox />
        </Box>
        <Box className="table-wrapper">
          <TableWrapper>
            <TableContainer component={Paper}>
              <Table aria-label="files table">
                <TableHead>
                  <TableRow>
                    <TableCell>4 Uploaded files</TableCell>
                    <TableCell>Date/Time</TableCell>
                    <TableCell>Uploaded by</TableCell>
                    <TableCell style={{ width: 50 }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array(3)
                    .fill(1)
                    .map((_item, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            <TableFileView
                              extension={"pdf"}
                              fileName={"PAG_382.pdf"}
                              fileTime={"Edited 8m ago"}
                            />
                          </TableCell>
                          <TableCell>
                            <Typography className="date-time">
                              09/13/24 14:14
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Box className="user-cell">
                              <Avatar
                                alt="Jane Cooper"
                                src={avtarIcon}
                                className="avatar-icon"
                              />
                              <Typography className="user-name">
                                Jane Cooper
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <IconButton
                              data-test-id={`more-menu-button-${index}`}
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? "long-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={(event) =>
                                this.handleOpenActionMenu(event, String(index))
                              }
                              className={`file-action-btn ${
                                open && String(index) === selectedRowId
                                  ? "file-action-btn-active"
                                  : ""
                              }`}
                            >
                              <MoreVert />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </TableWrapper>
        </Box>
        {this.actionMenu()}
        {this.replaceFileDialog()}
        {this.renderDeleteDialog()}
      </GeneralTabViewWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const GeneralTabViewWrapper = styled(Box)({
  padding: "20px 16px",
  "& .table-wrapper": {
    margin: "20px 0",
  },
});

const StyledMenu = styled(Menu)({
  "& .MuiPopover-paper": {
    boxShadow: "0px 4px 6px -2px #684EF333, 0px 12px 16px -4px #684EF333",
    border: `1px solid ${colors().tableBorder}`,
    minWidth: "148px",
    maxWidth: "148px",
    marginTop: 4,
  },
  "& .MuiListItem-button": {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    paddingTop: "9px",
    paddingBottom: "9px",
    "&:hover": {
      backgroundColor: "#F4F3FB",
    },
    "& .menu-icon": {
      fontSize: "18px",
    },
    "& .menu-item": {
      fontFamily: "DM Sans",
      fontSize: "14px",
      lineHeight: "21px",
      fontWeight: 400,
      color: colors().darkText,
    },
    "& .delete-menu-item": {
      color: colors().maximumRed,
    },
    "& .delete-menu-icon": {
      color: colors().maximumRed,
    },
  },
});

const TableWrapper = styled(Box)({
  width: "100%",
  fontFamily: "DM Sans",
  "& .MuiTableContainer-root": {
    boxShadow: "none",
  },
  "& .MuiTableCell-root": {
    fontFamily: "DM Sans",
    borderBottom: `1px solid ${colors().tableBorder}`,
    padding: "16px 0",
  },
  "& .MuiTableCell-head": {
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: 700,
    color: colors().darkText,
  },
  "& .date-time": {
    fontFamily: "DM Sans",
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: 400,
    color: colors().grayText,
  },
  "& .user-cell": {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  "& .avatar-icon": {
    width: 32,
    height: 32,
  },
  "& .user-name": {
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: 400,
    color: colors().darkText,
  },
  "& .file-action-btn": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    minWidth: "24px",
    minHeight: "24px",
    padding: 3,
    color: colors().darkText,
  },
  "& .file-action-btn-active": {
    background: colors().darkPurple,
  },
});
export default AiQueryHubFilesTab;
// Customizable Area End
