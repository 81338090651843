Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfpromptingcustomquery1";
exports.labelBodyText = "cfpromptingcustomquery1 Body";

exports.btnExampleTitle = "CLICK ME";
exports.versionLabel = "Version";
exports.neeveBtn = "Neeve";
exports.newQuery = "Start new query";
exports.generate = "Generate";
exports.queryPlaceHolder = "Add details which make your request more precise. Use @ to find tables and columns.";
exports.HistoryTabString = {
  delteDialogueTitle: "Are you sure you want to delete query?",
  deleteBtn : "Delete query",
  cancelBtn: "Cancel",

}
// Customizable Area End